<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${is_editing ? 'col-12 col-sm-10' : 'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.addCover') }}</h1>
        <p>
          {{ $t('creator.coverAtention') }}
        </p>

        <div>
          <b-form-file 
            v-model="file" 
            accept=".jpeg, .jpg, .png"
            browse-text="Buscar" 
            class="my-2" 
            @input="hasChangeFile"
            :disabled="file !== null"
            :placeholder="$t('creator.chooseFile')"
          >
          </b-form-file>
        </div>

        <b-col v-if="preview" class="col-12 p-0 py-2 border-bottom d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <b-avatar :src="preview.preview" square class="avatar-preview-sample"></b-avatar>
            <strong v-if="!preview.uuid">
              <span v-if="preview.name.length > 0">
                  {{utils.shortText(5, preview.name)}} <span v-if="preview.name.split(' ').length > 6">...</span>
              </span>
              <span v-else class="text-muted">{{ $t('creator.noCaption') }}</span>
              </strong>
            <strong v-else class="text-muted">{{ $t('creator.noCaption') }}</strong>
          </div>
          <b-button variant="flat-secondary" @click="deleteFile">
              <feather-icon icon="TrashIcon" size="20"></feather-icon>
          </b-button>
        </b-col>

        <b-col class="col-12 d-flex justify-content-center flex-wrap">
          <b-button 
            variant="blue-button-next-step" 
            class="blue-button-next-step my-2" 
            :disabled="!preview"
            @click="save()"
          > 
            <div v-if="loading_service">
              <b-spinner class="spinner-waiting-service"></b-spinner>
              {{ $t('creator.upLoading') }}
            </div>
            <div v-else-if="!has_slug">
              {{ $t('creator.saveStep') }}
            </div>
            <div v-else>
              {{ $t('creator.savePreview') }}
            </div>
          </b-button>
        </b-col>
        <small class="mt-2 w-100 text-center text-muted">{{ $t('creator.coverRequired') }}</small>

      </b-col>
    </b-row>
    <canvas-color 
      :image="file" 
      v-if="file !== null && palette_list.length === 0" 
      :key="canvas_updated"
      @color_palette="getColorPalette"
    ></canvas-color>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormFile,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue';
import utils from '@/libs/utils';
import casting_calls_services from '@/services/casting_calls';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'stepCoverImage',
  components: {
    BRow,
    BCol,
    BButton,
    BFormFile,
    BAvatar,
    BSpinner,
    CanvasColor: () => import('@/views/pages/lists/CanvasColor.vue')
  },
  props: {
    is_editing: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      utils,
      preview: null,
      casting_call: {},
      steps: {},
      loading_service: false,
      loaded_contents: false,
      file: null,
      canvas_updated: false,
      palette_list: [],
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug;
    },
  },
  methods: {
    getColorPalette(palette_list) {
      this.palette_list = palette_list;
    },
    hasChangeFile() {
      setTimeout(() => {
        if (this.file) {
          const url_blob = URL.createObjectURL(this.file);
          this.preview = {preview: url_blob, name: this.file.name};
          this.canvas_updated = !this.canvas_updated;   
        }
      },200)
    },
    getData() {
      if (this.has_slug) {
        this.casting_call = this.$route.params.casting_call_data;
        this.preview = {
          preview: this.casting_call.cover_image ? utils.getAssetUrl(this.casting_call.cover_image) : this.casting_call.cover_url, uuid: this.casting_call.uuid
        }
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid;
        if (this.casting_call_uuid) {
          casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
            this.casting_call = response;
            this.preview = {preview: utils.getAssetUrl(this.casting_call.cover_image), uuid: this.casting_call.uuid}
          });
        }
      }
    },
    deleteFile() {
      this.preview = null;
      this.file = null;
    },
    save() {
      this.loading_service = true;
      const form_data = new FormData();
      if (this.has_slug && this.file) {
        form_data.append('cover_image', this.file);
        form_data.append('main_color', this.palette_list.length > 0 ? this.palette_list.slice(0, 3).join(',') : '#8443a5')

        casting_calls_services.updateCastingCall(this.casting_call.uuid, form_data).then((response) => {
          this.$emit('update_campaign', response.response)
        });
      } else if (this.has_slug && !this.file) {
        this.$emit('close_sidebar')
      }  else if (this.steps && this.steps.content_type.value === "create_casting_ia") {
        form_data.append('brand_uuid', this.steps.brand.uuid);
        form_data.append('brief', this.steps.castingIA.brief);
        form_data.append('link', this.steps.castingIA.link);
        form_data.append('cover_image', this.file);
        casting_calls_services.createCastingCallIA(form_data).then(() => {
          this.$router.push({name: 'dashboard-analytics'})
        });
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.castingCallIa'),
              text: this.$t('creator.textCastingia'),
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
          })
        })
        localStorage.removeItem('steps');
        this.$root.$emit('quit_navbar_white');
      } else if (Object.keys(this.casting_call).length > 0) {

        form_data.append('brand_uuid', this.steps.brand.uuid);
        form_data.append('title', this.steps.title);
        form_data.append('content_type', this.steps.content_type.value);
        this.steps.work_types.forEach((work_type) => {
          form_data.append('work_types', work_type.value);
        });
        if (this.file) form_data.append('cover_image', this.file);
        casting_calls_services.updateCastingCall(this.casting_call.uuid, form_data).then(() => {
          this.$router.push({ name: 'steps_casting_call', params: {step: 'preview-card'}})
        });
      } else {
        form_data.append('brand_uuid', this.steps.brand.uuid);
        form_data.append('title', this.steps.title);
        form_data.append('content_type', this.steps.content_type.value);
        form_data.append('cover_image', this.file);
        form_data.append('main_color', this.palette_list.length > 0 ? this.palette_list.slice(0, 3).join(',') : '#8443a5')
        this.steps.work_types.forEach((work_type) => {
          form_data.append('work_types', work_type.value);
        });
        casting_calls_services.createCastingCall(form_data).then((response) => {
          this.steps['uuid'] = response.uuid;
          localStorage.setItem('steps', JSON.stringify(this.steps));
          this.loading_service = false;
          this.$router.push({ name: 'steps_casting_call', params: {step: 'preview-card'}})
        });
      }
    },
  },
}
</script>
<style scoped>
.spinner-waiting-service {
  width: 18px;
  height: 18px;
}
.avatar-preview-sample {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 1em !important;
  margin: 0 1em 0 0;
}
</style>